@import '../../../resources';
.nav-wrap {
  display: flex;
  justify-content: center;
  background-color: #f4f6fb;
  @include Out;
  .navbar {
    z-index: 10;
    width: 100%;
    background-color: #f4f6fb;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    padding: 0 30px;
    @include mobile-420px {
      padding: 0 20px;
    }
  }
  .navbar a {
    list-style: none;
    text-decoration: none;
  }
  .navbar .nav_contain {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .navbar .logo {
    height: 80px;
    display: flex;
    align-items: center;
  }
  .navbar .logo img {
    width: 150px;
  }
  .navbar .nav_list {
    display: flex;
    list-style: none;
    justify-content: space-evenly;
    align-items: center;
    @media screen and (max-width: 1240px) {
      padding-right: 30px;
    }
  }
  .navbar .nav_list li {
    position: relative;
    text-decoration: none;
    color: #09114a;
    letter-spacing: 0.5px;
    font-size: 16px;
    cursor: pointer;
    font-weight: 500;
  }
  .navbar .nav_list a:nth-child(1) li {
    padding-right: 50px;
  }
  .navbar .nav_list a:nth-child(2) li {
    padding-right: 50px;
  }
  .navbar .nav_list a:nth-child(3) li {
    padding-right: 50px;
  }
  .navbar .nav_list a:nth-child(4) li {
    padding-right: 50px;
  }
  .navbar .nav_list li .btn {
    margin-left: 250px;
    font-weight: 600;
    font-size: 20px;
    @include Out;
    color: #1c1c1c;
  }

  .navbar .nav_list li:hover {
    color: $mainColor;
  }
  .navbar .nav_list li.active {
    color: $mainColor;
  }
  .navbar .nav_list li::after {
    content: '';
    display: block;
    height: 3px;
    width: 0%;
    background-color: #09114a;
    position: absolute;
    transition: all ease-in-out 250ms;
    margin: 0 0 0 10%;
  }
  .hamburger {
    display: none;
  }
  .hamburger div {
    width: 30px;
    height: 3px;
    background: #09114a;
    margin: 5px;
    transition: all 0.3s ease;
  }
  @media screen and (max-width: 1024px) {
    .navbar {
      position: fixed;
      height: 100px;
    }
    .hamburger {
      display: block;
      position: absolute;
      cursor: pointer;
      right: 5%;
      top: 50%;
      transform: translate(-5%, -50%);
      z-index: 2;
      transition: all 0.7s ease;
    }

    .navbar .logo img {
      width: 130px;
    }
    .toggle .line1 {
      transform: rotate(-45deg) translate(-5px, 6px);
    }
    .toggle .line2 {
      transition: all 0.7s ease;
      width: 0;
    }
    .toggle .line3 {
      transform: rotate(45deg) translate(-5px, -6px);
    }
    .navbar .nav_list {
      position: fixed;
      background: #f4f6fb;
      height: 90vh;
      width: 100%;
      flex-direction: column;
      clip-path: circle(50px at 90% -20%);
      -webkit-clip-path: circle(50px at 90% -10%);
      transition: all 1s ease-out;
      pointer-events: none;
      text-align: center;
    }
    .navbar .nav_list.open {
      clip-path: circle(1000px at 90% -10%);
      -webkit-clip-path: circle(1000px at 90% -10%);
      pointer-events: all;
    }
    .navbar .nav_list li {
      opacity: 0;
    }

    .navbar .nav_list a:nth-child(1) li {
      transition: all 0.5s ease 0.2s;
      padding-right: 0;
    }
    .navbar .nav_list a:nth-child(2) li {
      transition: all 0.5s ease 0.4s;
      padding-right: 0;
    }
    .navbar .nav_list a:nth-child(3) li {
      transition: all 0.5s ease 0.6s;
      padding-right: 0;
    }
    .navbar .nav_list li:nth-child(4) {
      transition: all 0.5s ease 0.8s;
      padding-right: 0;
    }
    .navbar .nav_list li button {
      margin-left: 0;
    }
    .navbar .nav_list a:nth-child(6) li .btn {
      margin-left: 0;
    }
    .navbar .nav_list.open li {
      opacity: 1;
    }
  }

  @media screen and (max-width: 600px) {
    .navbar .logo {
      padding-top: 20px;
    }
    .navbar .logo img {
      width: 120px;
    }
  }
}
