@import '../../resources';
.home {
  .hero {
    height: calc(100vh - 80px);
    display: flex;
    background: url('../../assests/banner-bg.jpg');
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    justify-content: space-between;
    padding: 100px 0;
    @include Out;
    flex-wrap: wrap;
    padding-top: 150px;
    @include mobile-1024px {
      height: fit-content;
    }
    .left {
      width: 45%;
      padding-left: 30px;
      @include mobile-1024px {
        width: 100%;
        padding: 0 20px;
        margin-bottom: 30px;
      }
      h2 {
        margin-bottom: 10px;
        letter-spacing: 1.5px;
        color: $mainColor;
        font-size: 16px;
        font-weight: 400;
      }
      h1 {
        margin-bottom: 15px;
        font-size: 55px;
        color: $blackColor;
        font-weight: 700;
        @include mobile-420px {
          font-size: 40px;
        }
      }
      p {
        color: $optionalColor;
        font-size: 16px;
        margin-bottom: 15px;
        line-height: 1.9;
      }
      button {
        margin-top: 10px;
        background-color: $mainColor;
        color: #fff;
        padding: 12px 30px;
        display: inline-block;
        transition: 0.5s;
        position: relative;
        border: none;
        z-index: 1;
        text-align: center;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 500;
      }
    }
    .right {
      width: 53%;
      @include mobile-1024px {
        width: 100%;
        padding: 0 20px;
      }
      img {
        width: 100%;
      }
    }
  }
  .serve-1 {
    display: flex;
    justify-content: space-between;
    padding: 50px 30px;
    @include Out;
    flex-wrap: wrap;
    @include mobile-1024px {
      padding: 50px 20px;
    }
    .card {
      border: 1px solid #e5e5e5;
      border-radius: 25px;
      margin-bottom: 30px;
      padding: 40px 30px;
      text-align: center;
      transition: 0.5s;
      width: 32%;

      @include mobile-1024px {
        width: 48%;
        margin-bottom: 30px;
      }
      @include mobile-500px {
        width: 100%;
      }
      &:hover {
        transform: translateY(-8px);
        border-color: $mainColor;
        box-shadow: 0 25px 65px rgba(2, 152, 148, 0.08);
      }
      img {
        margin-bottom: 22px;
        width: 100%;
        max-width: 200px;
      }
      h2 {
        margin-bottom: 15px;
        font-size: 24px;
        font-weight: 600;
        color: $blackColor;
      }
      p {
        color: $optionalColor;
        font-size: 16px;
        margin-bottom: 15px;
        line-height: 1.9;
      }
    }
  }

  .counter {
    padding: 0 30px;
    @include mobile-1024px {
      padding: 0 20px;
    }
    .cards {
      background: url('../../assests/funfact-bg.jpg');
      background-position: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 25px;
      padding: 70px 30px 40px 30px;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      .card {
        text-align: center;
        padding: 0 20px;
        padding-bottom: 30px;
        @include Out;
        h1 {
          font-size: 72px;
          font-weight: 600;
          color: #fff;
          line-height: 1;
          margin-bottom: 2px;
        }
        p {
          margin-bottom: 0;
          color: #fff;
          font-weight: 600;
          font-size: 18px;
          letter-spacing: 1px;
        }
      }
    }
  }
  .services {
    position: relative;
    @include Out;
    .serve {
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
      img {
        max-width: 100%;
        height: auto;
      }
    }
    .container {
      padding: 100px 30px;
      .top {
        text-align: center;
        margin-bottom: 50px;
        h1 {
          font-size: 40px;
          font-weight: 400;
          color: $blackColor;
        }
        p {
          color: $optionalColor;
          font-size: 16px;
          margin-top: 10px;
          line-height: 1.9;
        }
      }
      .cards {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .card {
          width: 49%;
          margin-bottom: 30px;
          border: 1px solid #e5e5e5;
          background-color: #fff;
          border-radius: 15px;
          padding: 30px;
          transition: 0.5s;
          display: flex;
          @include mobile-1024px {
            width: 100%;
          }
          @include mobile-500px {
            flex-wrap: wrap;
          }
          .one {
            margin-right: 10px;
            img {
              max-width: 250px;
              width: 100%;
              border-radius: 10px;
            }
          }
          .two {
            h2 {
              font-size: 24px;
              font-weight: 600;
              color: $blackColor;
              margin-bottom: 12px;
            }
            p {
              color: $optionalColor;
              font-size: 16px;
              margin-bottom: 15px;
              line-height: 1.9;
            }
          }
          &:hover {
            transform: translateY(-5px);
            background-color: $mainColor;
            border: 1px solid $mainColor;
            .two {
              h2 {
                color: #fff;
              }
              p {
                color: #fff;
              }
            }
          }
        }
      }
      .but {
        text-align: center;
        padding: 30px 0;
        button {
          margin-top: 10px;
          background-color: $mainColor;
          color: #fff;
          padding: 12px 30px;
          display: inline-block;
          transition: 0.5s;
          position: relative;
          border: none;
          z-index: 1;
          text-align: center;
          border-radius: 5px;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
}
.contact {
  .hero {
    height: calc(50vh - 80px);
    background: url('../../assests/banner-bg.jpg');
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    @include Out;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mobile-1024px {
      height: fit-content;
    }
    .hero-wrap {
      text-align: center;
      h1 {
        color: $blackColor;
      }
      .tag {
        display: flex;
        align-items: center;
        justify-content: center;
        .first {
          color: $mainColor;
        }
      }
    }
  }
  .contact-list {
    display: flex;
    padding: 100px 30px;
    justify-content: space-between;
    @include Out;
    flex-wrap: wrap;
    .card {
      padding: 30px;
      margin-bottom: 30px;
      border-radius: 10px;
      transition: 0.5s;
      background-color: #fff;
      box-shadow: 0 8px 24px hsla(210, 8%, 62%, 0.2);
      width: 24%;
      display: flex;
      align-items: center;
      flex-direction: column;
      @include mobile-1024px {
        width: 49%;
        margin-bottom: 20px;
      }
      @include mobile-500px {
        width: 100%;
      }
      .icon-tag {
        height: 90px;
        width: 90px;
        font-size: 45px;
        position: relative;
        border-radius: 50%;
        color: $mainColor;
        background-color: #f9f9f9;
        transition: 0.5s;
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 45px;
        }
      }
      h2 {
        display: block;
        margin-bottom: 12px;
        font-size: 22px;
        font-weight: 600;
        color: $blackColor;
      }
      p {
        color: $optionalColor;
        font-size: 16px;
        margin-bottom: 10px;
        line-height: 1.9;
      }
      &:hover {
        box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
          0 30px 60px -30px rgba(107, 124, 147, 0.3);
        transform: translateY(-10px);
        .icon-tag {
          color: #fff;
          background-color: $mainColor;
        }
      }
    }
  }
}
.contact-us {
  padding: 0 30px;
  padding-bottom: 100px;
  @include Out;
  .top {
    text-align: center;
    margin-bottom: 50px;
    h1 {
      font-size: 40px;
      font-weight: 400;
      color: $blackColor;
    }
    p {
      color: $optionalColor;
      font-size: 16px;
      margin-top: 10px;
      line-height: 1.9;
    }
  }
  .form {
    background: url('../../assests/funfact-bg.jpg');
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 25px;
    padding: 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include mobile-1024px {
      padding: 50px 20px;
    }
    .card {
      width: 48%;
      margin-bottom: 30px;
      span {
        font-size: 10px;
        color: red;
      }
      @include mobile-500px {
        width: 100%;
      }
    }
    input,
    select {
      display: inline-block;
      height: 55px;
      border-radius: 8px;
      color: $blackColor;
      border: 1px solid #fff;
      background-color: #fff;
      outline: 0;
      box-shadow: unset;
      transition: 0.5s;
      width: 100%;
      padding: 0 20px;
    }
    .butt {
      width: 100%;
      text-align: center;
      button {
        margin-top: 10px;
        background-color: #fff;
        color: $mainColor;
        padding: 12px 30px;
        display: inline-block;
        transition: 0.5s;
        position: relative;
        border: none;
        z-index: 1;
        text-align: center;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
.footer {
  display: flex;
  justify-content: center;
  background: #0a1634;
  @include Out;
  .footer-wrap {
    width: 100%;
    padding: 50px 30px;
    padding-top: 100px;
    color: #fff;
    @include mobile-1024px {
      padding: 50px 20px;
    }
    .top {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 151px;
      .card {
        width: 18%;
        &:nth-child(1) {
          width: 24%;
        }
        @media screen and (max-width: '1000px') {
          width: 30%;
          padding-bottom: 30px;
          &:nth-child(1) {
            width: 60%;
          }
        }
        @include mobile-500px {
          width: 100%;
          text-align: center;
          &:nth-child(1) {
            width: 100%;
          }
        }
        img {
          width: 201px;
          padding-bottom: 10px;
        }
        h2 {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          font-variant: small-caps;
          color: $mainColor;
          padding-bottom: 16px;
        }
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 30px;
          padding-bottom: 20px;
          word-wrap: break-word;
        }
        a {
          text-decoration: none;
          color: #fff;
          cursor: pointer;
        }
      }
    }
    .bottom {
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #afadad;
      }
    }
  }
}
.services {
  .hero {
    height: calc(50vh - 80px);
    background: url('../../assests/banner-bg.jpg');
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    @include Out;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mobile-1024px {
      height: fit-content;
    }
    .hero-wrap {
      text-align: center;
      h1 {
        color: $blackColor;
      }
      .tag {
        display: flex;
        align-items: center;
        justify-content: center;
        .first {
          color: $mainColor;
        }
      }
    }
  }
  .cardX {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 50px 30px;
    @include Out;
    .card {
      width: 49%;
      margin-bottom: 30px;
      border: 1px solid #e5e5e5;
      background-color: #fff;
      border-radius: 15px;
      padding: 30px;
      transition: 0.5s;
      display: flex;
      @include mobile-1024px {
        width: 100%;
      }
      @include mobile-500px {
        flex-wrap: wrap;
      }
      .one {
        margin-right: 10px;
        img {
          max-width: 250px;
          width: 100%;
          border-radius: 10px;
        }
      }
      .two {
        h2 {
          font-size: 24px;
          font-weight: 600;
          color: $blackColor;
          margin-bottom: 12px;
        }
        p {
          color: $optionalColor;
          font-size: 16px;
          margin-bottom: 15px;
          line-height: 1.9;
        }
      }
      &:hover {
        transform: translateY(-5px);
        background-color: $mainColor;
        border: 1px solid $mainColor;
        .two {
          h2 {
            color: #fff;
          }
          p {
            color: #fff;
          }
        }
      }
    }
  }
}
.trust {
  position: relative;
  margin: 100px 0;
  @include Out;
  .cyber {
    position: absolute;
    left: -80px;
    top: -80px;
    z-index: -1;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    flex-wrap: wrap;
    @include mobile-1024px {
      padding: 0 20px;
    }
    .left {
      width: 45%;
      @include mobile-1024px {
        width: 100%;
        margin-bottom: 30px;
      }
      img {
        width: 100%;
      }
    }
    .right {
      width: 50%;
      @include mobile-1024px {
        width: 100%;
      }
      .top {
        p {
          margin-bottom: 15px;
          letter-spacing: 1.5px;
          color: $mainColor;
          font-size: 16px;
          font-weight: 400;
        }
        h2 {
          font-size: 38px;
          font-weight: 600;
        }
      }
      .cards {
        .card {
          position: relative;
          background-color: #fff;
          box-shadow: 0 25px 65px rgba(2, 152, 148, 0.1);
          border-radius: 15px;
          padding: 30px;
          margin-top: 30px;
          transition: 0.5s;
          display: flex;
          &:hover {
            transform: translateY(-5px);
          }
          @include mobile-500px {
            flex-wrap: wrap;
          }
          .two {
            padding-left: 10px;
            @include mobile-500px {
              width: 100%;
            }
            h2 {
              font-size: 22px;
              font-weight: 600;
              margin-bottom: 12px;
            }
            p {
              color: $optionalColor;
              font-size: 16px;
              line-height: 1.9;
            }
          }
          .one {
            img {
              width: 100px;
            }
          }
        }
      }
    }
    &.change {
      .left {
        width: 50%;
        @include mobile-1024px {
          width: 100%;
        }
      }
      .right {
        width: 45%;
        @include mobile-1024px {
          width: 100%;
          margin-bottom: 30px;
        }
        .diff {
          color: $optionalColor;
          font-size: 16px;
          margin-bottom: 15px;
          line-height: 1.9;
        }
        li {
          display: flex;
          align-items: center;
          list-style-type: none;
          margin-bottom: 15px;
          position: relative;
          font-weight: 600;
          span {
            color: $optionalColor;
            padding-left: 10px;
          }
          svg {
            color: $mainColor;
          }
        }
      }
    }
  }
}
.faq {
  .hero {
    height: calc(50vh - 80px);
    background: url('../../assests/banner-bg.jpg');
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    @include Out;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mobile-1024px {
      height: fit-content;
    }
    .hero-wrap {
      text-align: center;
      h1 {
        color: $blackColor;
      }
      .tag {
        display: flex;
        align-items: center;
        justify-content: center;
        .first {
          color: $mainColor;
        }
      }
    }
  }
  .questions {
    display: flex;
    justify-content: center;
    padding: 50px 20px;

    .questions-wrap {
      width: 100%;
      max-width: 1000px;
      .card {
        border-bottom: 1px solid $optionalColor;
        @include Out;
        .card-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px;
          h2 {
            font-weight: 600;
          }
          svg {
            color: $mainColor;
            font-size: 20px;
            cursor: pointer;
          }
        }
        .card-body {
          padding: 0 20px 20px 20px;
          transition: 0.5s;
        }
        &.active {
          border-bottom: none;
          background-color: #ecfbfb;
        }
      }
    }
  }
}
